import { clsx } from "clsx";
import { Icons } from "../_icons";
import { Button, type ButtonProps } from "~/components/shadcnui/ui/button";

export type LoadingButtonProps = ButtonProps & {
  readonly icon?: React.ReactNode;
  readonly isLoading: boolean;
};

export function LoadingButton(props: LoadingButtonProps): JSX.Element {
  const { icon, isLoading, children, className, ...rest } = props;

  return (
    <Button
      {...rest}
      className={clsx("transition-all", className)}
      {...(isLoading ? { disabled: true } : {})}
    >
      {isLoading ? (
        <>
          <Icons.Util.Loading className="mr-1 size-5 motion-safe:animate-spin" />
          {children}
        </>
      ) : (
        <div className="flex items-center justify-center">
          {icon}
          {children}
        </div>
      )}
    </Button>
  );
}
